$env: prod;
@charset "UTF-8";

@import "./color.scss";

body {
  font-size: 0.8rem;
  color: #000;
}

input,
textarea {
  font-family: Arial;
  font-size: 0.8rem;
}

select[disabled] {
  color: $disabled;
  background-image: url("../images/chevron_down_disabled.png") !important;
  // background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L6 4.79289L10.6464 0.146447C10.8417 -0.0488155 11.1583 -0.0488155 11.3536 0.146447C11.5488 0.341709 11.5488 0.658291 11.3536 0.853553L6.35355 5.85355C6.15829 6.04882 5.84171 6.04882 5.64645 5.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z' fill='#DDDDDD'/%3E%3C/svg%3E") !important;
}

select {
  background-image: url("../images/chevron_down.png") !important;
  // background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L6 4.79289L10.6464 0.146447C10.8417 -0.0488155 11.1583 -0.0488155 11.3536 0.146447C11.5488 0.341709 11.5488 0.658291 11.3536 0.853553L6.35355 5.85355C6.15829 6.04882 5.84171 6.04882 5.64645 5.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z' fill='#757575'/%3E%3C/svg%3E") !important;
  background-size: 11px 6px !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 15px) center !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-left: 12px;
  padding-right: 33px;

  &.placeholder {
    color: $gray100;
  }
}

select {
  background: white;
  border: 1px solid #92989a;
  border-radius: 4px;
  height: 25px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid $gray50;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: $primary;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1.33325L4.75 9.33325L1 5.69689' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  background-size: 12px 9px !important;
  background-position: center center !important;
  border: 2px solid $primary;
}

label {
  display: flex;
  align-items: center;

  input {
    margin: 0 5px;
  }
}

.inline-label {
  display: inline-flex;
  padding-right: 25px;
}

input:focus {
  outline: 1px solid #1e87f0;
}

select:focus {
  outline: 1px solid #1e87f0;
}

textarea:focus {
  outline: 1px solid #1e87f0;
}

input:not([type="checkbox"], [type="radio"]) {
  background: white;
  border: 1px solid #92989a;
  border-radius: 4px;
  height: 25px;
  box-sizing: border-box;
  padding: 5px;
}

textarea {
  border: 1px solid #92989a;
  border-radius: 4px;
  height: 87px;
  padding: 5px;
}

table {
  width: 100%;

  &,
  th,
  td {
    text-align: left;
    border-collapse: collapse;
  }

  th,
  td {
    font-weight: 400;
    border: 1px solid $gray100;
    padding: 5px;
  }

  thead {
    background: $gray300;
  }

  .header {
    background: $gray300;
  }

  .selected {
    background: $selected !important;
  }
}

.disabled-link {
  pointer-events: none;
}

/* ui-kit customize */
.uk-breadcrumb {
  line-height: 1.8rem;
  margin-bottom: 5px;
}

.uk-breadcrumb> :nth-child(n + 2):not(.uk-first-column)::before {
  content: ">";
  display: inline-block;
  margin: 0 20px 0 calc(20px - 4px);
  font-size: 0.875rem;
  color: #000;
}

.uk-breadcrumb>li>a {
  font-weight: 500;
  color: $blue300 !important;
  text-decoration: underline;
}

.uk-tab {
  margin: initial;
}

.uk-tab li {
  width: 100px;
  padding-left: initial;
}

.uk-tab::before {
  left: 0px;
  border-bottom: 1px solid $gray400;
}

.uk-tab {
  li {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid $gray400;

    &.uk-active {
      background: $blue80;
      border: 1px solid $blue80;
    }
  }
}

.uk-tab>.uk-data {
  width: 120px
}

.uk-tab>.uk-active>a {
  border: none;
  color: white;
}

.uk-tab div.right {
  position: absolute;
  right: 0;
  bottom: 10px;
}

/*共通*/
.container {
  padding: 0px 40px 0px 40px;
}

.uk-input,
.uk-select,
.uk-textarea {
  border-radius: 0.2rem;
  border: 1px solid #e3e5e5;
  color: #424242;
}

.uk-button--s {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: 6rem;
  height: 1.3rem;
  padding: 0 0.4rem;
  line-height: 1.3rem;
  display: table-cell;
}

.uk-button--m {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: 6rem;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button--l {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: 8rem;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button--w {
  border-radius: 0.4rem;
  font-size: 0.8rem;
  width: auto;
  height: 1.8rem;
  padding: 0 0.4rem;
  line-height: 1.8rem;
  display: table-cell;
}

.uk-button-cancel {
  color: #222;
  background-color: #e6eaf4;
  border: 1px solid #92989a;
  text-decoration-line: none;

  &:disabled,
  &:active {
    color: #222;
    background-color: #f2f6f7;
    text-decoration-line: none;
  }
}

.uk-button-cancel a {
  color: #222;
  text-decoration-line: none;
}

.uk-button-red {
  color: #FFF;
  background-color: #e06666;
  border: 1px solid #e06666;
}

.uk-button-red:active {
  color: #FFF;
  background-color: #df9e9e;
  border: 1px solid #df9e9e;
  text-decoration-line: none;
}

.uk-button-red a {
  color: #FFF;
  text-decoration-line: none;
}

.uk-button-yellow {
  color: #FFF;
  background-color: #fbbd05;
  border: 1px solid #fbbd05;
}

.uk-button-yellow:active {
  color: #FFF;
  background-color: #ffdc74;
  border: 1px solid #ffdc74;
  text-decoration-line: none;
}

.uk-button-yellow a {
  color: #FFF;
  text-decoration-line: none;
}

.uk-button-refer {
  color: #fff;
  background-color: #26589e;
  border: 1px solid #26589e;

  &:disabled,
  &:active {
    color: #fff;
    background-color: #92abce;
    border: 1px solid #92abce;
    text-decoration-line: none;
  }
}

.uk-button-refer a {
  color: #fff;
  text-decoration-line: none;
}

.uk-button-white {
  color: #26589e;
  background-color: #fff;
  border: 1px solid #26589e;
}

.error {
  color: $red;
  margin-left: 8px;
}

.error-form {
  border: 1px solid $red !important;
}

.error-box {
  color: $red;
  background: $lightRed;
  text-align: center;
  border: 1px solid;
  padding: 10px;
  margin-top: 5px;
}

.precaution {
  color: $red;
  margin: 6px 0px 6px 10px;
}

.background-gray239 {
  background-color: $gray239;
}

.width-10p {
  width: 10%;
}

.width-15p {
  width: 15%;
}

.width-30p {
  width: 30%;
}

.width-40p {
  width: 40%;
}

.width-100p {
  width: 100% !important;
}

.width-40 {
  width: 40px !important;
}

.width-60 {
  width: 60px;
}

.width-120 {
  width: 120px !important;
}

.width-4r {
  width: 4rem !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-70 {
  margin-top: 70px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.h-19 {
  height: 19px !important;
}

.h-87 {
  height: 87px !important;
}

.h-111 {
  height: 111px;
}

.h-195 {
  height: 195px;
}

.min-h-87 {
  min-height: 87px !important;
}

span.collect {
  float: right;
  vertical-align: -2px;
  font-size: 25px;
  color: #056114
}

span.manage {
  float: right;
  vertical-align: -2px;
  font-size: 25px;
  color: #D64F14
}

span.collect-top {
  color: #056114
}

span.manage-top {
  color: #D64F14
}

span.collect-top-circle {
  vertical-align: -2px;
  font-size: 25px;
  color: #056114
}

span.manage-top-circle {
  vertical-align: -2px;
  font-size: 25px;
  color: #D64F14
}

.border-box {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1px);
  height: 100%;
  border: 1px solid $gray100;
  pointer-events: none;
}

.border-bottom-none {
  border-bottom: none !important;
}

.table-length {
  width: 130px;
  height: 25px;
  box-sizing: border-box;
}

.pagination-container {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin: 0 0 40px 0;
  padding: 0;
}

.pagination {
  display: inline-flex;
  list-style: none;
  column-gap: 1rem;
  margin: 0;
  padding: 0;
}

.h-75 {
  height: 75%;
}

.leading-1 {
  line-height: 1;
}

.line-break {
  white-space: pre-wrap;
}

.w-100 {
  width: 100px !important;
}

/*ヘッダー*/
header {
  background-color: $headerColor;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  position: sticky;
  top: 0;
  z-index: 5;

  select {
    width: 180px;
    height: 28px;
    padding-left: 12px;
    padding-right: 33px;
  }

  .env-label {
    color: white;
  }
}

.header-right-item {
  display: flex;
  color: #fff;
  list-style: none;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

.header-username {
  margin-left: 1.2rem;
  text-decoration-line: underline;
}

.header-username a {
  color: #fff;
}

.sub-header {
  padding: 5px 40px 0px 40px;
  position: sticky;
  top: 40px;
  background: white;
  z-index: 2;

  &-title {
    font-size: 1rem;
    display: flex;
    border: solid 1px $gray100;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
    border: none;
    background-color: #eee;

    .stick {
      border: 2px solid $blue80;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
}

/*ログイン*/
#login {
  .login-container {
    width: 1000px;
    margin: 5rem auto;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: start;
    position: relative;
    gap: 50px;
    border: 2px solid #b7bec0;
    height: calc(100vh - 260px);
    min-height: 350px;
  }

  .login-item {
    display: inline-flex;
    flex-direction: column;
    width: 30rem;
    box-sizing: border-box;
  }

  .login-item-information {
    height: 100%;
  }

  .login-item-header {
    display: inline-flex;
    align-items: flex-start;
    padding: 0.8rem 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: $blue60 solid 2px;
  }

  .login-item-header p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .login-item-body p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .login-item-body span {
    font-size: 0.9rem;
    color: red;
  }

  .login-item-body-login {
    padding: 1rem 1rem 1rem 0
  }

  .login-item-body-information {
    display: grid;
    grid-gap: 30px;
    overflow-y: scroll;
    padding: 2rem 2rem;
  }

  .login-item-body-information section {
    padding-bottom: 30px;
  }

  .login-item-body-information section:not(:last-child) {
    border-bottom: 1px solid #b7bec0;
  }

  .login-item-body-information section p {
    margin: 0px
  }

  .login-item-body-information section p.section-header {
    margin-bottom: 10px;
  }

  .login-item-body-information section p.section-body {
    white-space: pre-wrap;
  }

  .mailadress,
  .password {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

  .mailadress p,
  .password p {
    text-align: center;
    width: 120px;
  }

  .mailadress-form,
  .password-form {
    width: 20rem;
  }
}

#buttonEye {
  border: none;
  background: none;
}

/*パスワード変更*/
#password-change {
  .password-change-form {
    margin: auto;
    display: inline-flex;
    flex-direction: column;
    width: 32rem;
    height: 22.5rem;
    box-sizing: border-box;
    border-radius: 0.6rem;
    border: 1px solid #b7bec0;
  }

  .password-change-buttons {
    display: flex;
  }

  .login-container {
    width: 100%;
    height: calc(100vh - 4rem);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .login-form-header {
    display: inline-flex;
    align-items: flex-start;
    padding: 0.8rem 3rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: $blue60;
  }

  .login-form-header p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }

  .login-form-in {
    padding: 1rem 2rem;
  }

  .login-form-in p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .login-form-in span {
    font-size: 0.9rem;
    color: red;
  }

  .password {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    justify-content: space-between;
    width: -webkit-fill-available;

    p {
      width: 120px;
    }
  }

  .password-form {
    width: 16rem;
  }
}

/* 進捗一覧 */
.condition-tbl {
  select {
    width: 180px;
  }

  input:not([type="checkbox"], [type="radio"]) {
    width: 100%;
  }
}

.container-progress {
  position: relative;
  background-color: white;
  z-index: 1;
}

.progress-tbl {
  //table-layout: fixed;
  border-collapse: collapse;

  &-wrap {
    overflow: auto;
    max-height: calc(100vh - 132px);
    min-height: 250px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    line-height: 1rem;
    border: none;
    white-space: normal;
    overflow-wrap: break-word;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .status-box {
      margin: 0 10px;
      text-align: left;
      white-space: nowrap;
    }
  }

  tr:nth-child(1) {
    .border-box {
      border-top: none;
    }

    th:nth-of-type(1) {
      min-width: 30px;
      padding: 0;
      left: 0;
      z-index: 2;

      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    th:nth-of-type(2) {
      left: 30px;
      z-index: 2;

      .border-box {
        width: calc(100% - 2px);
      }
    }

    th:nth-of-type(3) {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }
  }

  tr:nth-child(2) {
    th {
      top: 26px;

      .border-box {
        height: calc(100% - 1px);
      }
    }
  }

  th:last-child {
    .border-box {
      border-right: none;
    }
  }

  td:nth-of-type(1) {
    background: white;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  td:nth-of-type(2) {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    left: 30px;
    z-index: 1;
  }

  tbody {
    tr:last-of-type {
      td:nth-child(n+3) {
        border-bottom: none;
      }
    }

    tr td:nth-of-type(2) {
      .border-box {
        width: calc(100% - 2px);
      }
    }

    tr td:nth-of-type(3) {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  input[type="checkbox"]:checked {
    background-size: 10px 7px !important;
  }

  &.fixed-columns {

    tr:nth-child(1) {
      th {
        &:nth-child(-n+6) {
          z-index: 2;
        }
      }
    }

    th,
    td {
      &:nth-child(-n+6) {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1;
      }
    }

    th {
      .border-box {
        height: calc(100% - 1px);
      }
    }

    td:nth-child(-n+6) {
      background: white;
    }

    th,
    td {
      &:nth-child(1) {
        left: var(--left-1, 0);
      }

      &:nth-child(2) {
        left: var(--left-2, 0);
      }

      &:nth-child(3) {
        left: var(--left-3, 0);
      }

      &:nth-child(4) {
        left: var(--left-4, 0);
      }

      &:nth-child(5) {
        left: var(--left-5, 0);
      }

      &:nth-child(6) {
        left: var(--left-6, 0);

        .border-box {
          width: calc(100% - 2px);
          //border-right: 1px solid #92989a;;
        }
      }

      &:nth-child(7) {
        .border-box {
          width: 100%;
          border-left: none;
        }
      }
    }
  }
}

.info-tbl {
  table {

    &,
    th,
    td {
      border-collapse: collapse;
    }

    th,
    td {
      border: 1px solid #92989a;
      background: #efefef;
      padding: 5px;
      font-size: 12.8px;
      text-align: left;
      overflow-wrap: anywhere;
    }

    td:nth-child(2),
    td:nth-child(4) {
      background: white;
    }

    .label {
      width: 20%;
    }

    .content {
      white-space: pre-wrap;

      &.no-padding {
        padding: 0;
      }
    }

    .map-button-active {
      padding: 10px;
      position: relative;
    }

    td.no-border {
      border: 0;
    }

    td.white {
      background-color: white;
    }
  }

  +.info-tbl {
    margin-top: 10px;
  }

  .remarks {
    white-space: pre-wrap;
  }
}

.approve {

  .select-department {
    max-width: 20rem;
  }
}
.approve-tbl {
  margin-top: 10px;

  &,
  th,
  td {
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #92989A;
    background-color: white;
    padding: 5px;
    font-size: 12.8px;
    text-align: left;
  }

  th {
    background-color: #EFEFEF;
  }

  .disabled {
    background-color: $gray217;
  }

  .label {
    width: 20%;
    background-color: #EFEFEF;
  }

  .content {
    width: 30%;
  }

  .column-border-none {
    border: none;
    background-color: white;
  }
}

.attribute-tab {
  padding-top: 20px;
  position: sticky;
  top: 173px;
  background: white;
  z-index: 1;
  line-height: 1;

  a {
    height: 75%;
  }
}

.file-tbl {
  &-wrap {
    border: 1px solid 92989 A;
  }

  &-new {
    margin-bottom: 40px;
  }

  table {
    width: 100%;

    .media {
      width: 20%;

      img {
        width: 120px;
        height: 90px;
        object-fit: cover;
      }
    }

    .label {
      width: 16.6%;
    }

    .content {}

    .action {
      width: 10%;
      padding: 12.5px;

      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }

    .media_flex {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .media_img {
      position: relative;
      width: 95%;
      height: 95%;
    }

    .media_img:after {
      content: "";
      display: block;
    }

    .media_img img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }


  table th {
    background: #efefef;
  }

  table,
  table th,
  table td {
    border-collapse: collapse;
    font-size: 12.8px;
    border: 1px solid 92989 A;
    line-height: 1.4;
    min-height: initial;
    padding: 5px 10px;
    height: 32px;
  }

  &-separate {

    table {
      border-collapse: separate;
      border-spacing: 0;
    }

    table th,
    table td {
      border: none;
      border-right: 1px solid #92989a;
      border-bottom: 1px solid #92989a;
    }

    .border-top {
      border-top: 1px solid #92989a;
    }

    .border-left {
      border-left: 1px solid #92989a;
    }
  }
}

.group-tbl {
  &-wrap {
    margin: 10px 0;
    overflow: auto;
    max-height: calc(100vh - 220px);
    min-height: 100px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border: none;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .border-box {
      border-top: none;
    }
  }

  thead tr {
    th:first-of-type {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    th:last-of-type {
      .border-box {
        border-right: none;
      }
    }
  }

  tbody {
    tr:last-of-type {
      @for $i from 4 through 13 {
        td:nth-of-type(#{$i}) {
          border-bottom: none;
        }
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  th:nth-of-type(1) {
    width: 20px;
    text-align: center;
  }

  th:nth-of-type(3) {
    width: 55px;
    text-align: center;
  }
}

.user-tbl {
  &-wrap {
    margin: 10px 0;
    overflow: auto;
    max-height: calc(100vh - 220px);
    min-height: 100px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: left;
    line-height: 1rem;
    border: none;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .border-box {
      border-top: none;
    }
  }

  tbody {
    tr:last-of-type {
      @for $i from 4 through 13 {
        td:nth-of-type(#{$i}) {
          border-bottom: none;
        }
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  thead tr {
    th:first-of-type {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    th:last-of-type {
      .border-box {
        border-right: none;
      }
    }
  }

  th:nth-of-type(1) {
    width: 20px;
    text-align: center;
  }

  th:nth-of-type(2) {
    width: 20px;
    text-align: center;
  }

  th:nth-of-type(3) {
    width: 20px;
    text-align: center;
  }

  th:nth-of-type(12) {
    width: 100px;
    text-align: center;
  }

  th:nth-of-type(13) {
    width: 55px;
    text-align: center;
  }
}

.admin-tbl {
  td:nth-of-type(1) {
    width: 250px;
    background: $gray300;
  }

  .table-header-background {
    background: $gray300;
  }

  input:not([type="checkbox"], [type="radio"]) {
    width: 100%;
  }

  select {
    width: 100%;
  }

  .file-select {
    width: 40%;
    display: flex;

    div {
      margin-left: 10px;
      line-height: 1.8rem;
    }
  }
}

.apply-tbl {
  //table-layout: fixed;
  border-collapse: collapse;

  th {
    text-align: center;
    background: $gray300;
  }

  th:nth-of-type(1) {
    width: 30px;
  }

  th:nth-of-type(3) {
    width: 130px;
  }

  th:nth-of-type(4) {
    width: 60px;
  }

  th:nth-of-type(5) {
    width: 130px;
  }

  th:nth-of-type(6) {
    width: 220px;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  input[type="checkbox"]:checked {
    background-size: 10px 7px !important;
  }
}

.lock-label {
  color: $red;
}


.messages {
  height: calc(100vh - 501px);
  overflow: auto;

  hr {
    border-top: 1px solid $gray100;
  }

  .received-message {
    width: 70%;

    &-box {
      border: 1px solid $gray100;
      border-radius: 15px;
      padding: 20px;
      word-wrap: break-word;

      &-title {
        font-weight: bold;
        font-size: 0.8rem;
      }
    }

    &-time {
      text-align: right;
      margin-right: 10px;
    }

    &-name {
      margin-left: 10px;
    }
  }

  .sent-message {
    width: 70%;
    margin-left: auto;

    &-box {
      border: 1px solid $gray100;
      border-radius: 15px;
      padding: 20px;
      word-wrap: break-word;
      background: #cfe2f3;
    }

    &-time {
      margin-left: 10px;
    }

    &-name {
      text-align: right;
      margin-right: 10px;
    }
  }
}

.message-sendto-box {
  border: 1.5px solid $gray100;
  display: flex;
  width: fit-content;
  height: 40px;
  margin-top: 24px;

  div {
    border-right: 1.5px solid $gray100;
    background: $gray239;
    text-align: left;
    padding: 10px 32px 10px 10px;
  }

  select {
    border: none;
    width: 440px;
    height: 100%;
  }

  select:focus {
    outline: 0;
  }
}

.message-input-box {
  border: 1px solid $gray100;
  border-radius: 15px;
  padding: 20px 20px 12px;
  margin: 15px 0;

  textarea {
    width: 100%;
    min-height: 80px;
    max-height: 180px;
    border: none;
  }

  textarea:focus,
  input:focus {
    outline: 0;
  }

  hr {
    margin: 10px 0;
  }

  .file-drop {
    border: none;
    height: 40px;
    width: 100%;
  }
}

/* モーダル */
.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  align-items: center;

  &-text {
    font-size: 1rem;
  }

  &-content {
    background-color: #fefefe;
    margin: 0 auto;
    padding: 36px 24px;
    border: 1px solid #888;
    width: 450px;
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

.ticket-search {
  hr {
    border-top: 1px solid $gray100;
  }

  &-condition {
    border: 1px solid $gray100;
    padding: 10px 20px;

    .title {
      line-height: 25px;
      width: 100px;
    }
  }

  &-result {
    margin: 20px 0;

    &-item {
      .title {
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
  }
}

.public-tab {
  position: sticky;
  top: 174px;
  padding-top: 20px;
  background: white;
  min-height: 34px;
}

.autocomplete-menu {
  background: white;
  position: absolute;
  list-style: none;
  padding: 5px 10px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);

  li {
    padding: 5px 0;
    border-bottom: 1px solid $gray300;
    cursor: pointer;
  }
}

/* 顧客一覧 */
/*
.condition-tbl {
  select {
    width: 180px;
  }

  input:not([type="checkbox"]) {
    width: 100%;
  }
}
*/
.client-container {
  position: relative;
  background-color: white;
  z-index: 1;
}

.client-tbl {
  table-layout: fixed;
  border-collapse: collapse;

  &-wrap {
    overflow: auto;
    max-height: calc(100vh - 132px);
    min-height: 250px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border: none;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .border-box {
      border-top: none;
    }
  }

  .text-red {
    color: red;
  }

  th:nth-of-type(1) {
    width: 30px;
    padding: 0;
    left: 0;
    z-index: 2;

    .border-box {
      width: 100%;
      border-left: none;
    }
  }

  th:nth-of-type(2) {
    width: 50px;
    left: 30px;
    z-index: 2;

    .border-box {
      width: calc(100% - 2px);
    }
  }

  th:nth-of-type(3) {
    width: 80px;
    left: 30px;
    z-index: 2;

    .border-box {
      width: calc(100% - 2px);
    }
  }

  th:nth-of-type(4) {
    width: 50px;

    .border-box {
      width: 100%;
      border-left: none;
    }
  }

  th:nth-of-type(5) {
    width: 50px;
  }

  th:nth-of-type(6) {
    width: 90px;
  }

  th:nth-of-type(7) {
    width: 90px;
  }

  th:nth-of-type(8) {
    width: 180px;
  }

  th:nth-of-type(9) {
    width: 100px;
  }

  th:nth-of-type(10) {
    width: 100px;
  }

  th:nth-of-type(11) {
    width: 100px;
  }

  th:nth-of-type(12) {
    width: 70px;
  }

  th:nth-of-type(13) {
    width: 70px;

    .border-box {
      border-right: none;
    }
  }

  th:nth-of-type(14) {
    width: 60px;

    .border-box {
      border-right: none;
    }
  }

  td:nth-of-type(1) {
    background: white;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  td:nth-of-type(2) {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    left: 30px;
    z-index: 1;
  }

  tbody {
    tr:last-of-type {
      @for $i from 2 through 13 {
        td:nth-of-type(#{$i}) {
          border-bottom: none;
        }
      }
    }

    tr td:nth-of-type(2) {
      .border-box {
        width: calc(100% - 2px);
      }
    }

    tr td:nth-of-type(3) {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  input[type="checkbox"]:checked {
    background-size: 10px 7px !important;
  }
}

/*
.customer-uk-tab::before {
  left: 0px;
  border-bottom: 1px solid $gray400;
}

.customer-uk-tab {
  margin: initial;

  li {
    width: 100px;
    padding-left: initial;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid $gray400;

    &.uk-active {
      background: $blue80;
      border: 1px solid $blue80;
    }
  }
}

.customer-uk-tab>.uk-active>a {
  border: none;
  color: white;
}

.customer-uk-tab div.right {
  position: absolute;
  right: 0;
  bottom: 10px;
}
*/
/*子タブ*/
.customer-attribute-tab {
  padding-top: 10px;
  position: sticky;
  top: 172.5px;
  background: white;
  z-index: 1;

  a {
    text-align: center;
  }
}

table.customer-info-tbl {

  th,
  td {
    border-collapse: collapse;

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    input[type="month"],
    select {
      max-width: 50rem;
    }
  }

  input:not([type="checkbox"], [type="radio"]) {
    width: 100%;
  }

  textarea {
    width: 100%;
  }


  .label {
    width: 20%;
    min-width: 10rem;
    background-color: #EFEFEF;
  }

  .content {
    width: 80%;
  }

  +.customer-info-tbl {
    margin-top: 1rem;
  }
}

.form-pdf {
  flex: 1;
  height: calc(100vh - 260px);
}

.file-history-tbl {
  font-size: 0.8rem;

  th {
    text-align: center;

    &:nth-child(1) {
      width: 30px;
    }

    &:nth-child(2) {
      width: 120px;
    }

    &:nth-child(3) {
      width: 100px;
    }

    &:nth-child(4) {
      width: 250px;
    }
  }

  td {
    text-align: center;
    text-wrap: break-word;
  }
}

.map-drawing-tbl {
  width: 100%;
  margin: 0px;
  background: #ffffff;

  tr {
    background: #ffffff;
  }

  &,
  th,
  td {
    text-align: left;
    border-collapse: collapse;
    background: #ffffff;
  }

  th,
  td {
    font-weight: 400;
    border: none;
    /* 1px solid #efefef; */
    padding: 5px;
    background: #ffffff;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.report-tbl {
  width: 100%;
  max-width: 100%;

  th {
    text-align: center;
  }

  td {
    max-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .deleted {
    background: $gray217;
  }

  input[type="checkbox"][disabled] {
    background: $gray300;
    cursor: unset;
  }
}


.height-300 {
  height: 300px;
}

.border-bottom {
  border-bottom: 1px solid #92989a;
}

.padding-5 {
  padding: 5px;
}

.map-drawing-form {
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #92989a;
  padding: 5px;

  p {
    margin: 0 0 0 20px;
  }
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.gap-10 {
  gap: 10px;
}

/*アコーディオン*/
.toggle-wrapper {
  overflow: hidden;
}

.toggle-contents {
  //transition: max-height 300ms ease-in-out;

  &.hidden {
    max-height: 0;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;

    th:nth-of-type(2) {
      width: 8%;
    }

    th:nth-of-type(3) {
      width: 8%;
    }

    th:nth-of-type(4) {
      width: 8%;
    }

    th:nth-of-type(5) {
      width: 8%;
    }

    th:nth-of-type(6) {
      width: 8%;
    }

    th:nth-of-type(7) {
      width: 8%;
    }

    th:nth-of-type(8) {
      width: 8%;
    }

    th:nth-of-type(9) {
      width: 8%;
    }

    th:nth-of-type(11) {
      width: 3%;
    }

    thead th {
      position: sticky;
      top: 0;

      &:not(:first-child) {
        border-left: transparent;
      }

    }

    tbody td {
      border-top: transparent;

      &:not(:first-child) {
        border-left: transparent;
      }
    }

    input,
    select,
    textarea {
      width: 100%;
      box-sizing: border-box;
    }

    .border-right-2 {
      border-right: 2px solid #92989a;
    }
  }
}

.updated-municipalities-container {
  .toggle-area {
    border-left: none !important;
    border-right: none !important;
    background-color: white !important;
  }

  table {

    tbody tr {
      &.record-space {
        height: 30px !important;

        td {
          border-left: none !important;
          border-right: none !important;
          background-color: white !important;
        }
      }

      td {
        background-color: white !important;
      }
    }
  }
}


.new-input-contents td {
  border-top: 1px solid #92989a !important;
}

.certification-tbl {
  table {

    input,
    select {
      width: 100%;
      max-width: 100%;
    }

    &,
    th,
    td {
      border-collapse: collapse;
    }

    th {
      border: 1px solid #92989a;
      background: #efefef;
      padding: 5px 5px 5px 20px;
      font-size: 12.8px;
      text-align: left;
      overflow-wrap: anywhere;
    }

    tbody th {
      width: 15%;
    }

    .hidden-record {
      height: 30px !important;

      td {
        border-left: none !important;
        border-right: none !important;
        background-color: white !important;
      }
    }

    .content {
      white-space: pre-wrap;

      &.no-padding {
        padding: 0;
      }
    }

    td.no-border {
      border: 0;
    }

    .hidden {
      border: none;
      background-color: white;
    }
  }
}

.usage-tbl {
  border-collapse: collapse;

  &-wrap {
    overflow: auto;
    max-height: calc(100vh - 132px);
    min-height: 200px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
    border-left: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border-top: none;
    border-bottom: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:nth-of-type(2) {
    max-width: 100px;
    min-width: 100px;
  }

  td:nth-of-type(3) {
    max-width: 80px;
    min-width: 80px;
  }

  td:nth-of-type(4) {
    max-width: 150px;
    min-width: 150px;
  }

  td:nth-of-type(5) {
    max-width: 110px;
    min-width: 110px;
  }

}

.drm-pf-information-container {
  position: relative;
  background-color: white;
  z-index: 1;

  .condition-tbl {
    th {
      text-align: center;
    }

    td:nth-of-type(1) {
      width: 150px;
    }

    td:nth-of-type(2) {
      width: 240px;
    }

    td:nth-of-type(5) {
      width: 240px;
    }

    .period-box {
      display: flex;
      justify-content: space-between;

      input {
        width: 44%;
      }
    }
  }
}

.drm-pf-information-tbl {
  table-layout: fixed;
  border-collapse: collapse;

  &-wrap {
    overflow: auto;
    max-height: calc(100vh - 132px);
    min-height: 250px;
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border: none;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .border-box {
      border-top: none;
    }

    .status-box {
      padding: 5px;
      margin: 0 10px;
      text-align: left;
      white-space: nowrap;
    }
  }

  tr:nth-child(2) {
    th {
      .border-box {
        border-left: 1px solid #92989a;
        border-top: 1px solid #92989a;
        height: calc(100% - 1px);
      }
    }
  }

  th:nth-of-type(1) {
    width: 30px;
    padding: 0;
    left: 0;
    z-index: 2;

    .border-box {
      width: 100%;
      border-left: none;
    }
  }

  th:nth-of-type(2) {
    width: 80px;
    left: 30px;
    z-index: 2;

    .border-box {
      width: calc(100% - 2px);
    }
  }

  th:nth-of-type(3) {
    width: 80px;

    .border-box {
      width: 100%;
      border-left: none;
    }
  }

  th:nth-of-type(4) {
    //width: 110px;
  }

  th:nth-of-type(5) {
    //width: 110px;
  }

  th:nth-of-type(6) {
    width: 100px;
  }

  th:nth-of-type(7) {
    width: 80px;
  }

  th:nth-of-type(8) {
    width: 80px;
  }

  th:nth-of-type(9) {
    width: 80px;
  }

  th:nth-of-type(10) {
    width: 80px;
  }

  th:nth-of-type(11) {
    width: 80px;
  }

  th:nth-of-type(12) {
    width: 80px;

    .border-box {
      border-right: none;
    }
  }

  th:nth-of-type(13) {
    width: 80px;

    .border-box {
      border-right: none;
    }
  }

  td:nth-of-type(1) {
    background: white;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  td:nth-of-type(2) {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    left: 30px;
    z-index: 1;
  }

  tbody {
    tr:last-of-type {
      @for $i from 2 through 13 {
        td:nth-of-type(#{$i}) {
          border-bottom: none;
        }
      }
    }

    tr td:nth-of-type(2) {
      .border-box {
        width: calc(100% - 2px);
      }
    }

    tr td:nth-of-type(3) {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  input[type="checkbox"]:checked {
    background-size: 10px 7px !important;
  }
}

.drm-pf-information-admin-tbl {
  &-header>th {
    width: 250px;
    background: $gray300;
  }

  input:not([type="checkbox"], [type="radio"]) {
    width: 100%;
  }

  input[type="checkbox"][disabled] {
    background: $gray300;
    cursor: unset;
  }

  select {
    width: 100%;
  }

  .file-select {
    width: 40%;
    display: flex;

    div {
      margin-left: 10px;
      line-height: 1.8rem;
    }
  }

  .description {
    thead {
      background: transparent;
    }
  }

  td {
    word-break: break-all;
  }
}

table.API-table {
  table-layout: fixed;

  .label {
    width: 8rem;
    background: #efefef;
  }

  select {
    width: 180px;
  }
}


.layer-tbl {
  &-wrap {
    margin: 10px 0;
    overflow: auto;
    max-height: calc(100vh - 220px);
    position: relative;
    white-space: nowrap;
    border: 1px solid $gray100;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border: none;

    hr {
      border-top: 1px solid #bbb;
      margin: 2px 0 5px 0;
    }

    .border-box {
      border-top: none;
    }
  }

  thead tr {
    th:first-of-type {
      .border-box {
        width: 100%;
        border-left: none;
      }
    }

    th:last-of-type {
      .border-box {
        border-right: none;
      }
    }
  }

  tbody {
    tr:last-of-type {
      @for $i from 4 through 13 {
        td:nth-of-type(#{$i}) {
          border-bottom: none;
        }
      }
    }

    tr td:last-of-type {
      .border-box {
        border-right: none;
      }
    }

    tr td:first-of-type {
      .border-box {
        border-left: none;
        width: 100%;
      }
    }
  }

  th:nth-of-type(1) {
    width: 50px;
    text-align: center;
  }
  th:nth-of-type(2) {
    width: 30%;
    text-align: center;
  }
  th:nth-of-type(4) {
    width: 100px;
    text-align: center;
  }

  td:nth-of-type(1) {
    width: 50px;
    text-align: center;
  }
  td:nth-of-type(2) {
    width: 30%;
    text-align: center;
  }
  td:nth-of-type(4) {
    width: 100px;
    text-align: center;
  }
}


.layer-add-tbl {
  &-wrap {
    margin: 10px 0;
    overflow: auto;
    max-height: calc(100vh - 220px);
    position: relative;
    white-space: nowrap;
  }

  td {
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    text-align: center;
    background: $gray300;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    line-height: 1rem;
    border: none;

    .border-box {
      border-top: none;
    }
  }

  td:nth-of-type(1) {
    width: 50px;
    text-align: center;
  }
  td:nth-of-type(2) {
    width: 30%;
    text-align: center;
  }
  td:nth-of-type(4) {
    width: 100px;
    text-align: center;
    border: none;
  }

  input {
    width: 100%;
  }
}

.position-container {
  .position-half {
    width: 50%;
    margin: 10px;
  }
  .position-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .position-tbl {
    &-wrap {
      overflow: auto;
      max-height: calc(100vh - 220px);
      min-height: 100px;
      position: relative;
      white-space: nowrap;
      border: 1px solid $gray100;
    }

    td {
      position: relative;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none;
    }

    th {
      text-align: center;
      background: $gray300;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      text-align: center;
      line-height: 1rem;
      border: none;

      hr {
        border-top: 1px solid #bbb;
        margin: 2px 0 5px 0;
      }

      .border-box {
        border-top: none;
      }
    }

    thead tr {
      th:first-of-type {
        .border-box {
          width: 100%;
          border-left: none;
        }
      }

      th:last-of-type {
        .border-box {
          border-right: none;
        }
      }
    }

    tbody {
      tr:last-of-type {
        @for $i from 4 through 13 {
          td:nth-of-type(#{$i}) {
            border-bottom: none;
          }
        }
      }

      tr td:last-of-type {
        .border-box {
          border-right: none;
        }
      }

      tr td:first-of-type {
        .border-box {
          border-left: none;
          width: 100%;
        }
      }

      .add-button {
        width: 3rem;
      }
    }

    th:nth-of-type(1) {
      width: 20px;
      text-align: center;
    }

    th:nth-of-type(3) {
      width: 55px;
      text-align: center;
    }
  }

}

.approver-pattern-container {
  &-checkbox-padding {
    padding: 0 40px 0 0;
    display: flex;
  }

  .checkbox-container {
    width: 40px;
  }

  .select-department {
    min-width: 20rem;
  }
  .approver-pattern-tbl {
    table {
      &,
      th,
      td {
        border-collapse: collapse;
      }

      th,
      td {
        border: 1px solid #92989a;
        padding: 5px;
        font-size: 12.8px;
        text-align: left;
        overflow-wrap: anywhere;
      }

      th {
        background: #efefef;
      }

      td {
        background: white;
      }

      .label {
        width: 20%;
      }

      .content {
        white-space: pre-wrap;

        &.no-padding {
          padding: 0;
        }
      }

      td.no-border {
        border: 0;
      }

      td.white {
        background-color: white;
      }
    }

    .clear-border-side {
      border-left: 0;
      border-right: 0;
    }

    .clear-border-left {
      border-left: 0;
    }

    .clear-border-right {
      border-right: 0;
    }
  }
  /* CSS */
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }

  .close-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .close-button:focus {
    outline: 2px solid #005fcc;
  }

}


.sort-button {
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  border: 50px solid transparent;

  &-wrap {
    cursor: pointer;
    position: relative;

    &::before,&::after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      border: 5px solid transparent;
      right: 10px;
      top: 50%;
    }

    &::before {
      margin-top: -10px;
    }

    &::after {
      margin-top: 2px;
    }
  }

  &::before {
    margin-top: -5px;
    border-bottom: 5px solid #666;
  }

  &::after {
    margin-top: 105px;
    border-top: 5px solid #666;
  }

  // ホバー時
  &:hover {
    &::before,
    &::after {
      opacity: 0.6;
    }
  }

  // 昇順ソート時
  &.asc {
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 0.3;
    }
  }

  // 降順ソート時
  &.desc {
    &::before {
      opacity: 0.3;
    }
    &::after {
      opacity: 1;
    }
  }
}

.sort-indicator {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  &-wrap {
    position: relative;
    padding-right: 20px;
  }

  // 共通のボタンスタイル
  .arrow-btn {
    width: 14px;
    height: 14px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    opacity: 0.5;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      opacity: 1;
    }

    // 矢印の基本スタイル
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  // 上矢印ボタン
  .arrow-up {
    &::before {
      border-bottom: 6px solid #333;
    }
  }

  // 下矢印ボタン
  .arrow-down {
    &::before {
      border-top: 6px solid #333;
    }
  }
}

.uk-spinner-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}